
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


span.tiny {
	font-size: .8em;
	display: block;
	margin-top: 3px;
}

.email-update {
	margin-bottom: 20px;
}

/*Custom nav*/

/* navbar */
.navbar-default {
    background-color: #115aa0;
    border-color: #E7E7E7;
}
/* title */
.navbar-default .navbar-brand {
    color: #fff;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #f3fbfe;
}
/* link */
.navbar-default .navbar-nav > li > a {
    color: #fff;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #f3fbfe;
}
.navbar-default .navbar-nav > .active > a, 
.navbar-default .navbar-nav > .active > a:hover, 
.navbar-default .navbar-nav > .active > a:focus {
    color: #fff;
    background-color: #000;
}
.navbar-default .navbar-nav > .open > a, 
.navbar-default .navbar-nav > .open > a:hover, 
.navbar-default .navbar-nav > .open > a:focus {
    color: #555;
    background-color: #D5D5D5;
}
/* caret */
.navbar-default .navbar-nav > .dropdown > a .caret {
    border-top-color: #777;
    border-bottom-color: #777;
}
.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #333;
    border-bottom-color: #333;
}
.navbar-default .navbar-nav > .open > a .caret, 
.navbar-default .navbar-nav > .open > a:hover .caret, 
.navbar-default .navbar-nav > .open > a:focus .caret {
    border-top-color: #555;
    border-bottom-color: #555;
}
/* mobile version */
.navbar-default .navbar-toggle {
    border-color: #fff;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #115aa0;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}

/*Custom Alerts*/
.alert-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
}

.alert-callout-inprogress {
    border-left-color: #ce4844;
}

.alert-callout-warning {
    border-left-color: #aa6708;
}

.alert-callout-success {
    border-left-color: #3c763d;
}

.alert-callout .icon {
    font-size: 48px;
    margin-top: 10px;
}

.alert-callout-success .icon {
    color: #3c763d;
}

.alert-callout-inprogress .icon {
    color: #ce4844;
}

.clear {
    clear: both;
}

.left {
    float: left;
}

.right {
    float: right;
}

.dropDownContent {
    margin-top: 10px;
}

.jobItem h3 {
    padding-top: 0;
    margin-top: 0;
}

.table {
     margin-bottom: 5px; 
}

small {
    color: #A9A9A9;
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #777;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #333;
    }

    .table.date-dump {
        font-size: 12px;
    }
}

